<!--
 * @Description: 兼职列表首页
 * @Author: huacong
 * @Date: 2021-04-25 14:24:03
 * @LastEditTime: 2021-07-30 17:51:08
 * @LastEditors: huacong
-->
<template>
  <div class="contentBox">
    <section>
      <div class="searchBox">
        <el-input
          placeholder="输入职位关键词，如“一级建造师”等"
          class="searchInput"
          v-model="searchCer"
        >
          <template slot="append">
            <div @click="postSearch">
              <img class="mini-icon" :src="searchIcon" alt="" />
              <span class="mini-icon" style="top: -2px;">搜索</span>
            </div>
          </template>
        </el-input>
        <el-button
          type="primary"
          class="radius-25"
          style="width:120px;margin-left:16px;"
          v-prevent-repeat-click
          @click="postJobBtn"
          v-if="userInfo.u_identity != 1"
          >发布职位</el-button
        >
        <p style="margin: 10px 44px;">
          <span style="color: #999">热门岗位：</span>
          <el-button
            type="text"
            v-small-repeat-click
            class="hotworkBtn"
            @click="hotGangwei(item)"
            v-for="(item, index) in hotcerList"
            :key="index"
          >
            {{ item }}
          </el-button>
        </p>
      </div>
      <!-- 筛选条件 -->
      <div class="conditionBox">
        <el-form class="conditionForm" label-width="65px">
          <el-form-item label="分类：">
            <el-button
              type="text"
              v-small-repeat-click
              @click="clearCer($event)"
              size="mini"
              class="selbtn selcerBtn"
            >
              不限
            </el-button>
            <el-button
              type="text"
              v-small-repeat-click
              :class="
                searchInfo.j_certificate == item.certificate
                  ? 'selbtn selcerBtn active'
                  : 'selbtn selcerBtn'
              "
              @click="selCer($event, item.id, item.certificate)"
              v-for="item in cerList"
              :key="item.id"
              size="mini"
            >
              {{ item.certificate }}
            </el-button>
          </el-form-item>
          <el-form-item label="专业：">
            <el-button
              major="不限"
              type="text"
              v-small-repeat-click
              @click="clearmajor($event)"
              size="mini"
              class="selbtn selcerBtn"
            >
              不限
            </el-button>
            <el-button
              type="text"
              v-small-repeat-click
              :class="
                searchInfo.j_major == item.certificate
                  ? 'selbtn selmajorBtn active'
                  : 'selbtn selmajorBtn'
              "
              @click="selmajor($event, item.certificate)"
              v-for="item in majorList"
              :key="item.id"
              size="mini"
            >
              {{ item.certificate }}
            </el-button>
          </el-form-item>
          <el-form-item label="省份：" class="proviceBox">
            <el-button
              type="text"
              v-small-repeat-click
              @click="clearProvice($event)"
              size="mini"
              class="selbtn selproviceBtn"
            >
              不限
            </el-button>
            <el-button
              type="text"
              v-small-repeat-click
              @click="selProvice($event, item.value, item.label)"
              v-for="item in proviceData"
              :key="item.value"
              size="mini"
              class="selbtn selproviceBtn"
            >
              {{ item.label }}
            </el-button>
            <el-button
              type="text"
              v-if="morepro"
              @click="moreProbtn"
              class="foldbtn"
              >更多</el-button
            >
            <el-button
              type="text"
              v-if="packpro"
              @click="packProbtn"
              class="foldbtn"
              >收起</el-button
            >
          </el-form-item>
          <el-form-item label="城市：">
            <el-button
              type="text"
              v-small-repeat-click
              @click="clearCity($event)"
              size="mini"
              class="selbtn selcityBtn"
            >
              不限
            </el-button>
            <el-button
              type="text"
              v-small-repeat-click
              @click="selCity($event, item.label)"
              v-for="item in cityData"
              :key="item.value"
              size="mini"
              class="selbtn selcityBtn"
            >
              {{ item.label }}
            </el-button>
          </el-form-item>
          <el-form-item label="更多：">
            <el-select
              v-model="searchInfo.j_register"
              clearable
              @change="setRegist"
              placeholder="注册情况"
              size="small"
              class="margin-left-16"
            >
              <el-option label="初始注册" value="初始注册"> </el-option>
              <el-option label="转注册" value="转注册"> </el-option>
              <el-option label="初转不限" value="初转不限"> </el-option>
            </el-select>
            <el-select
              v-model="searchInfo.j_social_need"
              clearable
              @change="setSocial"
              placeholder="社保要求"
              size="small"
              class="margin-left-16"
            >
              <el-option label="不限" value="不限"> </el-option>
              <el-option label="社保唯一" value="社保唯一"> </el-option>
            </el-select>
          </el-form-item>
          <div class="text-center">
            <el-button
              type="text"
              style="color:#FF0000"
              v-prevent-repeat-click
              @click="clearSearch"
            >
              清除筛选条件
            </el-button>
          </div>
        </el-form>
      </div>
      <!-- 数据展示区域--表格 -->
      <div class="partTimeBox">
        <el-row :gutter="24">
          <el-col :span="16">
            <el-table :data="partData" class="partTimeTable">
              <el-table-column width="570px">
                <template slot-scope="scope">
                  <div class="leftPadding">
                    <div class="cername">
                      <span class="fontHover" @click="gopartTime(scope.row)">
                        {{ scope.row.j_certificate }}-{{ scope.row.j_major }}
                      </span>
                      <b class="smallFont">
                        {{ scope.row.j_area_province }}-{{
                          scope.row.j_area_city
                        }}
                      </b>
                    </div>
                    <div class="cerstate">
                      <span class="cerPay">
                        {{ scope.row.j_min_price }}万-{{
                          scope.row.j_max_price
                        }}万/{{ scope.row.j_sign_time }}
                      </span>
                      <span>{{ scope.row.j_register }}</span>
                      <span>{{ scope.row.j_purpose }}</span>
                      <span>
                        <span v-if="scope.row.j_social_need == '不限'">
                          社保</span
                        >{{ scope.row.j_social_need }}
                      </span>
                    </div>
                    <div class="cerstate">
                      <span>{{ scope.row.j_sign_method }}</span>
                      <span>{{ scope.row.j_pay_method }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column>
                <template slot-scope="scope">
                  <div class="leftPadding">
                    <div class="avatarBox">
                      <span>
                        <el-avatar :size="24" style="background:#fff;">
                          <el-image :src="scope.row.u_portrait">
                            <div slot="error" class="image-slot">
                              <i
                                class="el-icon-picture-outline error-img-24"
                              ></i>
                            </div>
                          </el-image>
                        </el-avatar>
                      </span>
                      <span style="font-weight: 600;color: #000;">{{
                        scope.row.u_names
                      }}</span>
                      <span style="float:right;padding-top: 6px;">
                        <span style="color: #999">专业能力：</span>
                        <span style="color: #f00">{{
                          scope.row.professionalism
                        }}</span>
                      </span>
                    </div>
                    <div
                      style="text-align: right; width: 190px"
                      class="overflowOmit"
                    >
                      <span>{{ scope.row.com_external_name }}</span>
                    </div>
                    <div style="text-align: right;">
                      <span style="color: #2573F1;">{{
                        scope.row.create_time
                      }}</span>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <div slot="empty" class="empty">
                <div>
                  <img :src="noDate" />
                </div>
                <span>暂无数据</span>
              </div>
            </el-table>
            <div class="pageBox">
              <el-pagination
                background
                @size-change="sizeChange1"
                @current-change="currentChange1"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableTotal"
              >
              </el-pagination>
            </div>
          </el-col>
          <el-col :span="8">
            <assess></assess>
          </el-col>
        </el-row>
      </div>
    </section>
  </div>
</template>
<script>
import $ from "jquery";
import { mapState, mapMutations } from "vuex";
import { provinceAndCityData } from "element-china-area-data";
import assess from "@/components/assess/assess.vue";
export default {
  name: "partTimeIndex",
  components: {
    assess,
  },
  data() {
    return {
      searchIcon: require("@/assets/icon/search-icon.png"),
      noDate: require("@/assets/img/noDate.png"),
      searchCer: "",
      cerList: [],
      majorList: [],
      searchInfo: {},
      proviceData: provinceAndCityData,
      cityData: [],
      partData: [],
      morepro: true,
      packpro: false,
      currentPage: 1,
      pagesize: 10,
      tableTotal: 0,
      firstGo: true,
      hotcerList: [
        "一级建造师",
        "二级建造师",
        "职称证书",
        "注册造价工程师",
        "注册咨询工程师",
        "注册监理工程师",
      ],
    };
  },
  computed: {
    ...mapState(["comId", "talmajor2"]),
    userInfo() {
      let k = localStorage.getItem("userInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  methods: {
    ...mapMutations([
      "changeTalcer2",
      "changeTalmajor2",
      "setcomRealeInfo",
      "setcomStaffInfo",
      "setcomReale",
    ]),
    // 获取证书列表
    getCerList() {
      this.$http.post("index/certificate/certificate").then((res) => {
        if (res.data.resultCode === 1) {
          this.cerList = res.data.resultData;
          let parmes = this.$route.params;
          if (parmes.cer != undefined) {
            this.searchInfo.j_certificate = parmes.cer;
            this.cerList.forEach((element) => {
              if (parmes.cer == element.certificate) {
                this.majorList = element.child;
              }
            });
          } else {
            this.majorList = res.data.resultData[0].child;
          }
        }
      });
    },
    // 选择证书分类 event:当前按钮  e:该证书在证书列表的id
    selCer(event, e, cer) {
      $(".selcerBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");

      this.cerList.forEach((element) => {
        if (e == element.id) {
          this.majorList = element.child;
        }
      });
      this.searchInfo.j_certificate = cer;
      this.searchInfo.j_major = "";
      this.searchCer = "";
      $(".selmajorBtn").removeClass("active");
      this.getParttimeList();
    },
    clearCer(event) {
      $(".selcerBtn").removeClass("active");
      $(".selmajorBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.j_certificate = "";
      this.searchInfo.j_major = "";
      this.searchCer = "";
      this.getParttimeList();
    },
    // 选择专业
    selmajor(event, major) {
      $(".selmajorBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.j_major = major;
      this.getParttimeList();
    },
    clearmajor(event) {
      $(".selmajorBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.j_major = "";
      this.getParttimeList();
    },
    /**
     * @description: 获取省份
     * @param  {event: 当前按钮}
     * @param  {e: 当前选项的值}
     * @param  {provice: 当前选的省份}
     * @return {*}
     */
    selProvice(event, e, provice) {
      $(".selproviceBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");

      this.proviceData.forEach((element) => {
        if (e == element.value) {
          this.cityData = element.children;
        }
      });
      this.searchInfo.province = provice;
      this.searchInfo.j_area_city = "";
      $(".selcityBtn").removeClass("active");
      this.getParttimeList();
    },
    clearProvice(event) {
      $(".selproviceBtn").removeClass("active");
      $(".selcityBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.province = "";
      this.searchInfo.j_area_city = "";
      this.getParttimeList();
    },
    // 选择城市city
    selCity(event, city) {
      $(".selcityBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.j_area_city = city;
      this.getParttimeList();
    },
    clearCity(event) {
      $(".selcityBtn").removeClass("active");
      var el = event.currentTarget;
      el.classList.add("active");
      this.searchInfo.j_area_city = "";
      this.getParttimeList();
    },
    // 获取兼职列表
    getParttimeList(page, limit) {
      if (page == undefined || limit == undefined) {
        this.searchInfo.page = 1;
        this.searchInfo.limit = 10;
      } else {
        this.searchInfo.page = this.currentPage;
        this.searchInfo.limit = this.pagesize;
      }
      let parmes = this.$route.params;
      if (this.firstGo) {
        if (parmes.cer != undefined) {
          this.searchInfo.j_certificate = parmes.cer;
        }
        if (parmes.major != undefined) {
          this.searchInfo.j_major = parmes.major;
        }
      }
      this.$http.post("index/parjob/getlist", this.searchInfo).then((res) => {
        if (res.data.resultCode === 1) {
          this.partData = res.data.resultData.list;
          this.tableTotal = parseInt(res.data.resultData.count);
          this.firstGo = false;
        }
      });
    },
    sizeChange1(e) {
      this.pagesize = e;
      this.currentPage = 1;
      this.getParttimeList(this.currentPage, this.pagesize);
    },
    currentChange1(e) {
      this.currentPage = e;
      this.getParttimeList(e, this.pagesize);
    },
    // 展示更多省份
    moreProbtn() {
      this.morepro = false;
      this.packpro = true;
      $(".proviceBox").css({ height: "auto" });
    },
    // 折叠省份
    packProbtn() {
      this.morepro = true;
      this.packpro = false;
      $(".proviceBox").css({ height: "40px" });
    },
    // 跳转职位详情
    gopartTime(item) {
      let routeUrl = this.$router.resolve({
        name: "partTimeInfo",
        query: { id: item.id, cer: item.j_certificate, major: item.j_major },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 选择注册情况
    setRegist() {
      this.getParttimeList();
    },
    // 选择社保情况
    setSocial() {
      this.getParttimeList();
    },
    // 清除筛选
    clearSearch() {
      $(".selcerBtn").removeClass("active");
      $(".selmajorBtn").removeClass("active");
      $(".selproviceBtn").removeClass("active");
      $(".selcityBtn").removeClass("active");
      this.searchInfo = {};
      this.getParttimeList();
    },
    postSearch() {
      this.searchInfo.j_certificate = this.searchCer;
      console.log(this.searchInfo.j_certificate);
      this.cerList.forEach((element) => {
        if (this.searchCer == element.certificate) {
          this.majorList = element.child;
        }
      });
      this.searchInfo.j_major = "";
      $(".selmajorBtn").removeClass("active");
      this.getParttimeList();
    },
    // 热门职位
    hotGangwei(item) {
      this.searchInfo.j_certificate = item;
      this.cerList.forEach((element) => {
        if (this.searchCer == element.certificate) {
          this.majorList = element.child;
        }
      });
      this.searchInfo.j_major = "";
      $(".selmajorBtn").removeClass("active");
      this.getParttimeList();
    },
    // 发布职位
    postJobBtn() {
      if (JSON.stringify(this.userInfo) == "{}") {
        this.$message.warning("您还没有登录，现在前往登录界面！");
        setTimeout(() => {
          this.$router.push("/Login");
        }, 1600);
        return;
      }
      if (this.userInfo.u_identity == 1) {
        this.$message.warning("您是人才角色，不能发布职位！");
        return;
      }
      this.$http
        .post("index/recharge/recharge", { id: this.userInfo.uid })
        .then((res) => {
          if (res.data.resultCode === 1) {
            this.vipInfo = res.data.resultData;
            if (this.vipInfo.u_surplus_push < 1) {
              this.$message.warning(
                "您的发布次数已用完，您可以前往会员服务购买会员！"
              );
            } else {
              if (this.comId != 0 || this.userInfo.u_company != 0) {
                this.$router.push("/comPage/postType");
              } else {
                this.$message.success(
                  "您还未认证企业，现在前往完善认证企业信息！"
                );
                setTimeout(() => {
                  this.$router.push("comrealSel");
                }, 1200);
              }
            }
          }
        });
      this.getRealeInfo();
    },
    getRealeInfo() {
      // 企业认证信息
      if (this.comId == 0) {
        this.$http.post("company/index/getcompanyinfo").then((res) => {
          if (res.data.resultCode === 1) {
            this.setcomRealeInfo({
              comRealeInfo: res.data.resultData,
            });
          }
        });
      } else {
        this.$http
          .post("company/index/getcompanyinfo", { u_company: this.comId })
          .then((res) => {
            if (res.data.resultCode === 1) {
              this.setcomRealeInfo({
                comRealeInfo: res.data.resultData,
              });
            }
          });
      }
      // 企业员工信息
      this.$http.post("company/user/getpersonal").then((res) => {
        if (res.data.resultCode === 1) {
          this.setcomStaffInfo({
            comStaffInfo: res.data.resultData,
          });
        }
      });
    },
  },
  mounted() {
    this.getCerList();
    this.getParttimeList();
  },
};
</script>
<style scoped>
.searchBox {
  margin: 0px 100px;
}
.searchInput {
  width: calc(100% - 136px);
}
/deep/.searchBox .el-input__inner {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
/deep/.searchBox .el-input-group__append {
  background-color: #0070fa;
  border-color: #0070fa;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  color: #fff;
  cursor: pointer;
}
.el-input-group__append img {
  width: 20px;
}
.conditionBox {
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 24px;
}
/deep/.conditionForm .el-form-item {
  margin-bottom: 0;
  padding: 7px 20px;
  border-bottom: 1px solid #e8e8e8;
}
/deep/.conditionForm .el-form-item__label {
  color: #333;
  font-size: 17px;
  font-weight: bold;
}
.proviceBox {
  height: 40px;
  overflow: hidden;
  position: relative;
}
.foldbtn {
  position: absolute;
  right: -10px;
  top: 0px;
}
.conditionForm .selbtn {
  color: #333;
  font-size: 13px;
}
.el-button--text.color999 {
  color: #999;
}
.selcerBtn,
.selmajorBtn,
.selcityBtn,
.selproviceBtn {
  padding-left: 7px;
  padding-right: 7px;
}
.selcerBtn.active,
.selmajorBtn.active,
.selcityBtn.active,
.selproviceBtn.active {
  background: #2573f1;
  border-radius: 4px;
  color: #fff;
}
/deep/ .partTimeTable .el-table__header-wrapper {
  height: 0;
}
.cername span {
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;
  color: #333;
}
.leftPadding {
  padding-left: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.cerstate {
  padding-top: 8px;
  line-height: 1;
}
.cerstate span {
  color: #999;
}
.cerstate span:not(:last-child) {
  margin-right: 24px;
}
.cerstate .cerPay {
  font-size: 17px;
  font-weight: bold;
  color: #ff0000;
}
.avatarBox {
  line-height: 12px;
}
.avatarBox > span:first-child {
  vertical-align: middle;
  margin-right: 12px;
}
.tr:hover {
  box-shadow: 0px 5px 14px 6px rgba(173, 193, 213, 0.5);
}
.smallFont {
  color: #999;
  font-size: 14px;
  margin-left: 10px;
}
.hotworkBtn:hover {
  color: #2573f1;
}
.hotworkBtn {
  color: #333;
}
</style>
